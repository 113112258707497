import { useContext, useEffect } from 'react'
import { navigate } from 'gatsby'

import AuthorizationContext from '../authorization/Context'
import { URL_PATHS } from '../../constants/urlPaths'

export const usePrivateRoute = () => {
  const { isLoggedIn, isChecked } = useContext(AuthorizationContext)

  useEffect(() => {
    if (isChecked && !isLoggedIn) {
      navigate(URL_PATHS.VIP_LOGIN)
    }
  }, [isChecked, isLoggedIn])

  return { isVisible: isLoggedIn }
}
