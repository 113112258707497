import React, { useMemo } from 'react'

import SEO from '../../components/Seo'
import Container from '../../components/Container'
import TruncatedHero from '../../components/Heros/TruncatedHero/TruncatedHero'
import { Body, Typo5, Heading2 } from '../../components/Typography/Typography'
import InfoBox from '../../components/InfoBox/InfoBox'
import { TRUNCATED_HERO_TYPES } from '../../constants/truncatedHeroTypes'
import { usePrivateRoute } from '../hooks/usePrivateRoute'
import { generateHero } from '../utils'
import FlexContentBlocks from '../queries/FlexContentBlocks'

const Dashboard = ({ data }) => {
  const { isVisible } = usePrivateRoute()

  const page = data.wp.page?.vipWelcomeAcf

  const {
    slantDirection,
    bgGradient,
    hasBgGradient,
    isHeroHigh,
    heroBgColorMobile,
    heroBgColorDesktop,
    heroColorMobile,
    heroColorDesktop,
    infoBoxOpacity,
    infoBoxBg,
    heroImages,
  } = generateHero(page)

  const { infoBoxTitle, infoBoxContent, infoBoxAction } = useMemo(() => {
    const {
      infoboxTitle: infoBoxTitle,
      infoboxContent: infoBoxContent,
      action,
    } = page?.heroFields || {}
    const actionType = action?.showAction
      ? action?.action?.internalOrExternal
      : null

    return {
      infoBoxTitle,
      infoBoxContent,
      infoBoxAction: actionType ? action?.action[actionType].title : null,
    }
  }, [page])

  const contentBlocks = page?.contentBlocks?.content

  if (!isVisible) {
    return null
  }

  return (
    <>
      <SEO title="Vip Welcome" />
      <Container
        bg={[heroBgColorMobile, '', heroBgColorDesktop]}
        color={[heroColorMobile, '', heroColorDesktop]}
      >
        <TruncatedHero
          positionImg="50% 0%"
          heroType={TRUNCATED_HERO_TYPES.withGradientOnMobile}
          heroSlantType={slantDirection || 'left'}
          heroImages={heroImages}
          opacityImg={0.9}
          gradient={hasBgGradient ? bgGradient : null}
          long={isHeroHigh}
        >
          <Container mt={['-4rem', '', '0']}>
            <InfoBox
              bg={infoBoxBg}
              bgOpacity={infoBoxOpacity}
              title={
                <Heading2 as="h1">
                  <strong>{infoBoxTitle}</strong>
                </Heading2>
              }
              content={
                <>
                  <Body opacity="0.9">{infoBoxContent}</Body>
                  {!!infoBoxAction && (
                    <Typo5 color="yellow" textAlign="right">
                      {infoBoxAction}&nbsp;
                      <span role="img" aria-label="img">
                        🥳
                      </span>
                    </Typo5>
                  )}
                </>
              }
            />
          </Container>
        </TruncatedHero>
      </Container>
      <FlexContentBlocks contentBlocks={contentBlocks} pageData={data} />
    </>
  )
}

export default Dashboard
